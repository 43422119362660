import axios from "axios";

window.axios = axios;

window.axios.defaults.baseURL = "https://api.members.blitzpay.com.br/v1";
window.axios.defaults.timeout = 1000 * 60 * 5;

// if (document.location.href.indexOf("localhost") > 0) {
//   window.axios.defaults.baseURL = "https://api-staging.members.blitzpay.com.br/v1";
// }
if (
  document.location.href.indexOf("staging.club") > 0 ||
  document.location.href.indexOf(".localhost") > 0
) {
  window.axios.defaults.baseURL =
    "https://api-staging.members.blitzpay.com.br/v1";
}

const TOKEN = "_apiToken";

const setAxiosHeader = token => {
  if (token) {
    window.axios.defaults.headers.common.Authorization = `JWT ${token}`;
  } else {
    delete window.axios.defaults.headers.common.Authorization;
  }
};

const setToken = token => {
  localStorage.setItem(TOKEN, token);
  setAxiosHeader(token);
};

const removeToken = () => {
  localStorage.removeItem(TOKEN);
  setAxiosHeader(null);
};

const getToken = () => {
  return localStorage.getItem(TOKEN);
};

const init = () => {
  const token = getToken();
  if (token) {
    setAxiosHeader(token);
  }
};

export { setToken, getToken, removeToken, init };
