<template>
  <form class="lesson-search" @submit.prevent="submit">
    <button
      type="button"
      class="btn pl-0"
      v-if="!showing"
      @click="showing = true"
    >
      <i class="icon-search lead" />
    </button>

    <div v-if="showing" class="d-flex justify-content-end align-items-center">
      <input
        v-model="text"
        type="text"
        class="form-control"
        placeholder="Pesquisar"
      />
      <button type="button" class="btn pl-0" @click="close">
        <i class="icon-close lead" />
      </button>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      text: "",
      showing: false
    };
  },
  watch: {
    text(value) {
      this.$emit("input", value);
    }
  },
  methods: {
    close() {
      this.showing = false;
      this.text = "";
    },
    submit() {
      this.$router.push({
        name: "search",
        query: { search: this.text }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.lesson-search {
  button {
    max-width: 48px;
  }

  input {
    background: transparent;
    height: 30px;
    padding: 6px 7px 7px 10px;
    border-radius: 4px;
    border: 1px solid #6b6e73;
  }

  h5 {
    line-height: 38px;
  }

  .form-control {
    background-color: transparent;
    font-family: "IBM Plex Sans";
    display: flex;
    height: 28px;
    padding: 6px 70px 7px 10px;
    align-items: center;
    border-radius: 4px;
    border: 1px solid var(--l-neutral-45);
    font-size: 14px;
    color: #fff;

    &::placeholder {
      color: var(--l-elevation-16);
    }
  }

  .close-search {
    min-height: 28px;
    min-width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
