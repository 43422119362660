<template>
  <div class="template-profile-dropdown">
    <b-dropdown
      variant="link"
      :toggle-class="`text-decoration-none ${toggleClasses}`"
      no-caret
    >
      <template #button-content>
        <div :class="`user-circle ${theme}`">
          <i class="icon-user-filled"></i>
        </div>
      </template>
      <div class="p-2">
        <div class="d-flex align-content-center justify-content-center mb-3">
          <input-image id="profile-input-img" v-model="photo">
            <template #default>
              <div :class="`user-circle ${theme} picker mr-2`">
                <i class="icon-user-filled"></i>
                <span class="edit"><i class="icon-edit"></i></span>
              </div>
            </template>
            <template #filled-image>
              <div
                v-if="photo"
                class="user-photo mr-2"
                :style="`background-image: url(${photo})`"
              >
                <span class="edit"><i class="icon-edit"></i></span>
              </div>
            </template>
          </input-image>
          <div>
            <p class="name mb-0">{{ name }}</p>
            <small class="email">{{ email }}</small>
          </div>
        </div>
        <div class="hr"></div>
      </div>
      <b-dropdown-item :to="{ name: 'account' }">
        <i class="icon-user-filled mr-2"></i>Perfil
      </b-dropdown-item>
      <b-dropdown-item @click="$emit('faq')">
        <i class="icon-info mr-2"></i>FAQ
      </b-dropdown-item>
      <div class="mb-2"></div>
      <b-dropdown-item @click="$emit('logout')">
        <i class="icon-power red mr-2"></i><span class="red">Sair</span>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>
import InputImage from "@/components/InputImage";
import get from "lodash/get";
export default {
  components: { InputImage },
  props: {
    toggleClasses: {
      type: String,
      default: ""
    }
  },
  computed: {
    name() {
      return get(this.$user, "user.name", "Your name");
    },
    email() {
      return get(this.$user, "user.email", "e-mail");
    },
    photo: {
      get() {
        return get(this.$user, "user.photo", "");
      },
      set(value) {
        this.$store
          .dispatch("user/update", { photo: value })
          .then(() => {
            this.$message.success("Sua foto de perfil foi atualizada!");
            this.$store.dispatch("user/get");
          })
          .catch(() => {
            this.$message.error("Sua foto de perfil foi atualizada!");
          });
      }
    },
    theme() {
      return this.$setup.theme || "dark";
    },
    firstName() {
      if (!this.$user.user.name) return "";
      let names = this.$user.user.name.split(" ");
      return names[0];
    }
  }
};
</script>
<style lang="scss" scoped>
.template-profile-dropdown {
  ::v-deep .dropdown-toggle::after {
    display: none;
  }

  ::v-deep .dropdown-menu {
    padding: 24px 16px;
  }

  .user-circle {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #282237;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    i {
      font-size: 16px;
    }

    &.light {
      background: linear-gradient(225deg, #fff 0%, #dddee6 100%);
      box-shadow: 0px 4px 4px 0px rgba(168, 168, 168, 0.25) inset;
      i {
        color: rgba(57, 52, 68, 0.8);
      }
    }

    &.dark {
      background: #282237;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    }
  }

  .red {
    color: var(--l-error-2, rgba(255, 117, 126, 0.86));
  }

  .user-photo {
    width: 58px !important;
    height: 58px !important;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .picker {
    position: relative;
    width: 58px !important;
    height: 58px !important;

    .icon-user-filled {
      color: rgba(255, 255, 255, 0.8);
      font-size: 32px;
    }
  }

  .edit {
    position: absolute;
    bottom: -4%;
    right: -4%;
    background: #6b3ead;
    border-radius: 50%;
    width: 19px;
    height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 12px;
      color: var(--l-elevation-24);
    }
  }

  .name {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
  }

  .email {
    font-weight: 400;
    color: var(--l-elevation-12);
  }

  .hr {
    height: 1px;
    width: 100%;
    background-color: var(--l-neutral-125);
    opacity: 0.4;
  }
}
</style>
