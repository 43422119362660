import store from "@/store";
import get from "lodash/get";

export default {
  install(Vue) {
    Vue.prototype.$setup = new Vue({
      store,
      computed: {
        setup() {
          return get(this.$store, "state.setup");
        },
        config() {
          return get(this.setup, "config");
        },
        theme() {
          return get(this.config, "style", "light");
        }
      }
    });
  }
};
