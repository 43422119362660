<template>
  <action-dispatcher
    action="user/get"
    @error="logout"
    class="template-default d-flex flex-column"
    :class="`theme-${$setup.theme}`"
  >
    <transition name="fade">
      <div class="blocking-bg d-lg-none" v-if="showMenu" @click="closeMenu" />
    </transition>

    <nav>
      <div class="container-fluid h-100 d-flex align-items-center">
        <button
          class="btn btn-menu p-0"
          :class="{ show: showMenu }"
          @click.stop="clickMenuButton"
        >
          <i class="menu" :class="showMenu ? 'icon-close' : 'icon-menu'"></i>
        </button>
        <div class="flex-fill"></div>
        <router-link :to="{ name: 'home' }" class="brand mr-5">
          <div
            class="img"
            :style="
              $setup.setup.logo
                ? `background-image: url(${$setup.setup.logo})`
                : ''
            "
          />
        </router-link>
        <div class="d-flex align-items-center justify-content-end">
          <search class="d-none d-lg-block" />
          <profile-dropdown
            @logout="logoutDialog = true"
            @faq="faqDialog = true"
            toggleClasses="nav-size pr-0"
          />
        </div>
      </div>
    </nav>

    <faq-modal v-model="faqDialog" />

    <confirmation
      v-model="logoutDialog"
      @confirm="logout"
      text="Tem certeza que deseja sair?"
      confirm-button-text="Sair"
    />

    <action-dispatcher action="modules/getAll" class="flex-fill">
      <mobile-menu
        v-model="showMenu"
        @close="closeMenu"
        @logout="logoutDialog = true"
      />

      <main class="flex-fill">
        <div class="position-relative slot-wrapper">
          <slot v-if="!loading" />
          <b-spinner variant="primary" v-else />
        </div>
      </main>
    </action-dispatcher>

    <app-footer class="mt-5" @logout="logoutDialog = true" />

    <transition name="fade">
      <div class="backdrop" v-if="showMenu" @click="showMenu = false"></div>
    </transition>
  </action-dispatcher>
</template>

<script>
import { mapState } from "vuex";
import ProfileDropdown from "../components/templates/ProfileDropdown";
import MobileMenu from "../components/templates/MobileMenu";
import AppFooter from "../components/templates/AppFooter";
import FaqModal from "../components/templates/FaqModal";
import Search from "../components/templates/Search";

export default {
  components: {
    ProfileDropdown,
    MobileMenu,
    AppFooter,
    FaqModal,
    Search
  },
  data() {
    return {
      popupDialog: false,
      faqDialog: false,
      showMenu: false,
      logoutDialog: false,
      menuButtonTimeout: null
    };
  },
  computed: {
    ...mapState(["loading"])
  },
  methods: {
    clickMenuButton() {
      this.setMenuVisibility(!this.showMenu);
    },
    setMenuVisibility(visibility) {
      if (this.menuButtonTimeout) {
        clearTimeout(this.menuButtonTimeout);
      }
      this.menuButtonTimeout = setTimeout(() => {
        this.showMenu = visibility;
      }, 150);
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push({ name: "login" });
      });
    },
    clickMenu() {
      this.closeMenu();
    },
    closeMenu() {
      this.showMenu = false;
    }
  },
  mounted() {
    this.$store.dispatch("courses/getGroups");
    this.$store.dispatch("modules/getFAQ");
  }
};
</script>

<style lang="scss" scoped>
.template-default {
  min-height: 100vh;
  display: flex;

  &.sale {
    background: #e63946;

    ::v-deep footer * {
      color: white !important;
    }
  }

  .nav-size {
    width: 0px !important;
    min-width: 0px !important;
  }

  .btn-menu {
    width: 0px;
    min-width: 0px;
    height: 72px;
    z-index: 10;
    position: fixed;

    &.show {
      i {
        color: white !important;
        border-radius: 6px;
      }
    }
  }

  .blocking-bg {
    position: fixed;
    z-index: 3;
    background: rgba(0, 10, 20, 0.5);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  i {
    font-size: 24px;
    min-width: 46px;

    &.menu {
      font-size: 32px;
      line-height: 44px;
      color: rgba(51, 51, 51, 0.6);
    }
  }

  .open-submenu {
    .icon-chevron-right {
      transform: rotate(90deg);
    }
  }

  main {
    width: 100%;
    position: relative;
    margin-top: 72px;
  }

  nav {
    display: flex;
    align-items: center;
    min-height: 72px;
    max-height: 72px;
    width: 100%;
    top: 0;
    flex: 1 1 auto;
    position: fixed;
    z-index: 3;
    background: linear-gradient(black, rgba(0, 0, 0, 0));

    z-index: 5;

    ::v-deep .btn {
      color: white;
      font-size: 12px;
    }

    @media screen and (max-width: 575px) {
      right: 0;
      top: 0;
      left: 0;
      z-index: 3;
      position: fixed;
    }

    .brand {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);

      .img {
        height: 40px;
        width: 160px;
        background-image: url("../assets/images/logo-white.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
      }
    }

    i {
      font-size: 20px;
    }
  }

  .slot-wrapper {
    transition: 0.3s padding ease;
  }

  .submenu {
    width: 100%;

    a {
      padding-left: 32px !important;
    }
  }

  .backdrop {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
</style>
