<template>
  <div id="app">
    <action-dispatcher action="getSetup" @success="successSetup">
      <component :is="template">
        <router-view :key="$route.fullPath" />
      </component>
    </action-dispatcher>

    <snackbar />
  </div>
</template>

<script>
import { getToken } from "./setup/api";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("user", ["logged"]),
    ...mapState(["setup"]),
    template() {
      return this.$route.meta.template;
    }
  },
  beforeMount() {
    if (!getToken() && this.$route.meta.protected) {
      this.$router.replace({ name: "login" });
    } else if (getToken()) {
      if (["/login", "/register"].includes(document.location.pathname)) {
        this.$router.replace({ name: "home" });
      }
    }
  },
  watch: {
    $route: function() {
      if (!this.logged && this.$route.meta.protected) {
        this.$router.replace({ name: "login" });
      } else if (this.$user.user && !this.$user.user.is_enable) {
        this.$router.replace({ name: "sale" });
      }
      window.scrollTo(0, 0);
    }
  },
  methods: {
    successSetup(data) {
      if (!data.product_id) {
        if (document.location.href.indexOf("staging.club") > 0) {
          document.location.href = "https://staging.club.blitzpay.com.br/";
        } else {
          document.location.href = "https://club.blitzpay.com.br/";
        }
      }
      if (data.name) {
        document.title = `${data.name} - Blitzpay`;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#app {
  overflow: hidden;
}
</style>
