import { removeToken } from "../setup/api";

export default {
  getSetup(context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get("/setup/config", {
          headers: { Authorization: null }
        })
        .then(response => {
          context.commit("setSetup", response.data);
          resolve(response.data);
        })
        .catch(reject);
    });
  },

  logout(context) {
    return new Promise(resolve => {
      removeToken();
      context.commit("user/reset");
      resolve();
    });
  }
};
