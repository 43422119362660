<template>
  <div class="templates-main-menu">
    <router-link
      :to="{ name: 'home' }"
      class="mt-3 btn home"
      @click.native="$emit('click-menu')"
    >
      <span>Início</span>
    </router-link>

    <component
      v-for="(module, i) in modules"
      :key="i"
      :is="isBlocked(module) ? 'div' : 'router-link'"
      :to="{
        name: 'module',
        params: { id: module.id }
      }"
      class="btn"
      @click.native="clickDropdown"
    >
      <img
        v-if="isBlocked(module)"
        class="lock"
        src="@/assets/images/icon-lock.png"
      />
      <span>{{ module.name }}</span>
    </component>

    <button class="btn mt-3" @click="modal = true" v-if="groups.length">
      Meus grupos
    </button>

    <groups-modal v-model="modal"></groups-modal>

    <div class="flex-fill"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import GroupsModal from "@/components/home/MyGroups.vue";
import get from "lodash/get";

export default {
  components: { GroupsModal },
  data() {
    return {
      modal: false
    };
  },
  computed: {
    ...mapState("modules", ["modules"]),
    ...mapState("courses", ["groups"])
  },
  methods: {
    clickDropdown() {
      this.$emit("click-menu");
      this.$refs.dropdown.hide(true);
    },
    isBlocked(module) {
      return get(module, "is_blocked.status");
    }
  }
};
</script>

<style lang="scss" scoped>
.templates-main-menu {
  background: #181523;
  ::v-deep .btn {
    display: flex;
    align-items: center;
  }

  ::v-deep .dropdown-item {
    text-transform: uppercase !important;
    color: #8395a7 !important;
    font-size: 12px;
    font-weight: bold;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .btn {
    padding: 8px 8px;
  }

  .lock {
    max-height: 16px;
    margin-right: 6px;
  }
}
</style>
