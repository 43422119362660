<template>
  <router-link
    class="home-progress-module-card border-0"
    :to="{
      name: 'lesson',
      params: { ...lesson.module, lesson: lesson.id }
    }"
  >
    <div class="img" :style="style"></div>

    <div class="progress mb-1 flex-fill">
      <div
        class="progress-bar bg-primary"
        role="progressbar"
        :style="`width: ${progress}%`"
      ></div>
    </div>

    <small class="module-title">
      {{ lesson.module.name }}
    </small>

    <h6 v-if="lesson">
      {{ lesson.name }}
    </h6>
  </router-link>
</template>

<script>
import { youtubeParser } from "@/functions/helpers";

export default {
  props: {
    lesson: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    progress() {
      if (!this.lesson.duration) return 0;
      const durationArray = this.lesson.duration.split(":");
      const seconds =
        parseInt(durationArray[0]) * 60 * 60 +
        parseInt(durationArray[1]) * 60 +
        parseInt(durationArray[2]);
      return Math.round((100 * this.lesson.progress.stopped_at) / seconds);
    },
    style() {
      let image = this.lesson.picture || this.lesson.module.cover;

      if (!image && this.lesson.link_media.includes("youtu")) {
        image = `https://i.ytimg.com/vi/${youtubeParser(
          this.lesson.link_media
        )}/hqdefault.jpg`;
      }

      return image ? `background-image: url(${image})` : "";
    }
  },
  methods: {
    onClick() {
      this.$router.push({
        name: "module",
        params: {
          ...this.lesson.module
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.home-progress-module-card {
  text-align: left;
  padding: 0;
  border-radius: 16px;
  width: 100%;

  &:hover {
    opacity: 0.8;
    text-decoration: none;
  }

  .img {
    height: 0;
    width: 100%;
    display: block;
    padding-bottom: 55%;
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
    margin-bottom: -8px;
    background-color: black;
  }

  .progress {
    height: 8px;
    width: 100%;
    border-radius: 0;
    background: none;
  }

  .module-title {
    display: block;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 0;
    margin-right: 8px;
    overflow: hidden;
  }

  h6 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  small {
    color: rgba(128, 128, 128, 1);
  }
}
</style>
