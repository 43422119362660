export default {
  getAll(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/modules/lessons/u/list/${id}`)
        .then(response => {
          context.commit("setLessons", response.data);
          resolve();
        })
        .catch(reject);
    });
  },
  search(context, params) {
    const queryString = new URLSearchParams(params).toString();
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/modules/lessons/?${queryString}`)
        .then(response => {
          context.commit("setLessons", response.data.results);
          context.commit("setCount", response.data.count);
          resolve();
        })
        .catch(reject);
    });
  }
};
