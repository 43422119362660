export default {
  setFaq(state, faq) {
    state.faq = faq;
  },
  setModules(state, modules) {
    state.modules = modules;
  },
  setModule(state, module) {
    state.module = module;
  },
  setLastLessons(state, lastLessons) {
    state.lastLessons = lastLessons;
  }
};
