var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view-home"},[(_vm.headerModule)?_c('header',{style:(_vm.headerStyle)},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-5"},[(_vm.certificateAvailable)?_c('div',[_c('h1',[_vm._v("Certificado disponível")]),_c('a',{staticClass:"btn btn-primary d-inline-flex align-items-center justify-content-center",attrs:{"href":_vm.certificateUrl,"target":"_blank"}},[_vm._v(" Baixar certificado ")])]):[_c('h1',[_vm._v(_vm._s(_vm.headerModule.name))]),_c('p',{staticClass:"my-4",domProps:{"innerHTML":_vm._s(_vm.headerModule.description)}}),_c('div',{staticClass:"d-flex align-items-center justify-content-start"},[_c('action-dispatcher',{staticClass:"mx-2",attrs:{"action":"lessons/getAll","parameters":_vm.headerModule.id,"small":""}},[(_vm.currentLesson)?_c('router-link',{staticClass:"btn btn-primary d-inline-flex align-items-center justify-content-center",attrs:{"to":{
                    name: 'lesson',
                    params: Object.assign({}, _vm.headerParams, {lesson: _vm.currentLesson.id})
                  }}},[_c('i',{staticClass:"icon-play"})]):_vm._e()],1),_c('router-link',{staticClass:"btn btn-secondary px-5 d-inline-flex align-items-center mx-2",attrs:{"to":{
                  name: 'module',
                  params: _vm.headerParams
                }}},[_vm._v(" Saiba mais ")])],1)]],2)])])]):_vm._e(),_c('div',{staticClass:"container-fluid pt-5"},[_c('action-dispatcher',{attrs:{"action":"modules/getLastLessons"}},[(_vm.lastLessons.length)?_c('div',{staticClass:"card continue-lessons-card p-4 mb-5"},[_c('div',{staticClass:"card-body px-2 py-0"},[_c('h5',{staticClass:"mb-4"},[_vm._v(" Continue assistindo ")]),_c('div',{staticClass:"row my-n3"},_vm._l((_vm.progressModules),function(lesson,i){return _c('div',{key:i,staticClass:"col-xl-4 col-md-6 my-3 d-flex"},[_c('progress-module-card',{staticClass:"flex-fill",attrs:{"lesson":lesson}})],1)}),0)])]):_vm._e()]),_c('h5',{staticClass:"my-3"},[_vm._v("Todos os Módulos")]),_c('carousel',{staticClass:"mb-5",attrs:{"per-page":1,"per-page-custom":[
        [200, 2],
        [400, 3],
        [768, 4],
        [992, 6]
      ],"pagination-enabled":false,"scroll-per-page":"","navigation-enabled":"","navigation-next-label":"<i class='icon-chevron-right'></i>","navigation-prev-label":"<i class='icon-chevron-left'></i>"}},_vm._l((_vm.modules),function(module,i){return _c('slide',{key:i,staticClass:"px-1"},[_c('module-card',{staticClass:"flex-fill",attrs:{"module":module}})],1)}),1),_vm._l((_vm.modulesPerCategory),function(value,key){return _c('div',{key:key},[_c('h5',{staticClass:"my-3"},[_vm._v(_vm._s(key))]),_c('carousel',{staticClass:"mx-n3 mb-5",attrs:{"per-page":1,"per-page-custom":[
          [576, 2],
          [768, 3],
          [992, 4]
        ],"pagination-enabled":false,"scroll-per-page":"","navigation-enabled":"","navigation-next-label":"<i class='icon-chevron-right'></i>","navigation-prev-label":"<i class='icon-chevron-left'></i>"}},_vm._l((value),function(module,i){return _c('slide',{key:i,staticClass:"px-3"},[_c('module-card',{staticClass:"flex-fill",attrs:{"module":module}})],1)}),1)],1)}),_c('action-dispatcher',{attrs:{"action":"courses/getRelated"}},[(_vm.relatedCourses.length)?[_c('h5',{staticClass:"my-3"},[_vm._v(" Cursos relacionados ")]),_c('carousel',{staticClass:"mb-5",attrs:{"per-page":1,"per-page-custom":[
            [576, 2],
            [768, 3],
            [992, 4]
          ],"pagination-enabled":false,"scroll-per-page":"","navigation-enabled":"","navigation-next-label":"<i class='icon-chevron-right'></i>","navigation-prev-label":"<i class='icon-chevron-left'></i>"}},_vm._l((_vm.relatedCourses),function(course,i){return _c('slide',{key:i,staticClass:"px-1"},[_c('related-course',{staticClass:"flex-fill",attrs:{"course":course}})],1)}),1)]:_vm._e()],2)],2),_c('action-dispatcher',{attrs:{"action":"releases/getReleases","parameters":_vm.setup.product_id}},[(_vm.releases.length)?[_c('div',{staticClass:"my-3 d-none d-lg-block container-fluid"},[_c('h5',[_vm._v(" Próximos lançamentos ")]),_vm._l((_vm.releases),function(release,i){return _c('div',{key:i,staticClass:"my-3"},[_c('releases',{attrs:{"release":release}})],1)})],2),_c('div',{staticClass:"d-lg-none pt-3"},[_c('h5',{staticClass:"ml-4 mb-3"},[_vm._v(" Próximos lançamentos ")]),_c('carousel',{attrs:{"scrollPerPage":false,"perPage":1,"pagination-enabled":false}},_vm._l((_vm.releases),function(release,i){return _c('slide',{key:i},[_c('releases',{attrs:{"release":release}})],1)}),1)],1)]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }