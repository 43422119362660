export default {
  add(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(`/comments/${data.lesson_id}`, data)
        .then(resolve)
        .catch(reject);
    });
  },
  interact(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/comments/interaction", data)
        .then(resolve)
        .catch(reject);
    });
  },
  like(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/comments/like/" + id)
        .then(resolve)
        .catch(reject);
    });
  },
  remove(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .delete(`/comments/view/${id}`)
        .then(resolve)
        .catch(reject);
    });
  },
  edit(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .put(`/comments/view/${data.id}`, data)
        .then(resolve)
        .catch(reject);
    });
  },
  getAll(context, data) {
    const queryString = new URLSearchParams(data.params).toString();
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/comments/${data.params.id}?${queryString}`)
        .then(response => {
          let comment_mutation = data?.replaceComments
            ? "setComments"
            : "addComments";
          context.commit(comment_mutation, response.data.results);
          context.commit("setReviews", response.data.reviews);
          context.commit("setCount", response.data.count);
          resolve(response.data);
        })
        .catch(reject);
    });
  }
};
