<template>
  <b-modal
    id="releases-modal"
    v-model="show"
    hide-header
    hide-footer
    centered
    size="md"
  >
    <button class="btn text-secondary close" @click="show = false">
      <i class="icon-close" />
    </button>
    <div class="p-3">
      <div class="title pt-2 pb-2">
        <strong>
          Receber Aviso
        </strong>
      </div>
      <div>
        <p class="pt-lg-4 pt-3 text1">
          Você deseja receber a notificação sobre este lançamento neste E-mail
          já cadastrado em seu usuário?
        </p>
      </div>
      <div>
        <b-form-radio-group class="options" id="radio-group" v-model="option">
          <b-form-radio
            class="pt-lg-1"
            value="yes"
            :class="{ 'font-weight-bold text-primary': useEmail }"
            >Sim, receber no E-mail {{ userEmail }}</b-form-radio
          >
          <b-form-radio
            class="pt-lg-3 pt-3"
            value="no"
            :class="{ 'font-weight-bold text-primary': !useEmail }"
            >Não, desejo receber em outro E-mail.</b-form-radio
          >
        </b-form-radio-group>
      </div>
      <div v-if="!useEmail">
        <form-group
          class="pt-3"
          id="email"
          v-model="email"
          placeholder="Digite novo E-mail."
        />
        <div class="text2 pb-2">
          <small
            >Este é o E-mail onde vamos entrar em contato com você sobre este
            lançamento.</small
          >
        </div>
      </div>
      <div class="d-flex align-items-center pt-4">
        <button class="btn btn-cancel flex-fill mr-2" @click="show = false">
          Cancelar
        </button>
        <button
          class="btn flex-fill ml-2"
          @click="submit"
          :disabled="!useEmail && !email.length"
          :class="
            !useEmail && !email.length ? 'btn-secondary' : 'btn-outline-success'
          "
        >
          <div
            v-if="loading"
            class="d-flex justify-content-center align-items-center"
          >
            <b-spinner small />
          </div>
          <span v-else>
            Receber aviso
          </span>
        </button>
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      show: this.value,
      option: "yes",
      email: ""
    };
  },
  computed: {
    useEmail() {
      return this.option == "yes";
    },
    userEmail() {
      return this.$user.user.email;
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    release: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    onSuccess() {
      this.$message.success("Aviso enviado!");
      this.show = false;
      window.location.reload();
    },
    submit() {
      this.loading = true;

      let params = {
        next_release_id: this.release.id,
        email: this.useEmail ? this.userEmail : this.email
      };

      this.$store
        .dispatch("releases/postNotification", params)
        .then(this.onSuccess)
        .catch(error => {
          this.$message.error("Erro ao enviar o aviso");
          this.errors = error.response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  watch: {
    value(val) {
      if (!val) this.option = "yes";
      this.show = val;
    },
    show(v) {
      this.$emit("input", v);
    }
  }
};
</script>
<style lang="scss" scoped>
.title {
  font-size: 18px;
  text-align: center;
  margin-left: 26px;
}

.text1 {
  font-size: 12px;
}

.text2 {
  font-size: 13px;
  line-height: 1px;
}

.options {
  font-size: 14px;
  line-height: 24px;
  color: #8d8799;
}

.btn-cancel {
  background: transparent;
  border: none;
  color: #8d8799;

  &:hover {
    background-color: white;
    color: #8d8799;
  }
}

@media screen and (max-width: 576px) {
  .text1 {
    font-size: 13px;
  }

  .text2 {
    font-size: 12px;
    line-height: 16px;
  }
}
::v-deep .custom-control {
  label {
    &::before {
      background: transparent !important;
    }
  }
}
</style>
