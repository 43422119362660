import store from "../store";
import get from "lodash/get";
import { dayDiff } from "../functions/helpers";

export default {
  install(Vue) {
    Vue.prototype.$user = new Vue({
      store,
      computed: {
        course() {
          const setup = get(this.$store, "state.setup", {});
          return get(this.$store, "state.user.user.my_courses", []).find(
            course => course.product_id === setup.product_id
          );
        },
        user() {
          return get(this.$store, "state.user.user");
        },
        service() {
          return get(this.$store, "state.user.user.service");
        },
        isInactive() {
          return (
            this.user && (!this.service.is_active || this.daysToExpire < 0)
          );
        },
        daysToExpire() {
          if (!this.user || !this.service) return "";
          return dayDiff(this.service.expired_at);
        }
      }
    });
  }
};
