<template>
  <aside class="templates-mobile-menu" :class="{ open: value }">
    <main-menu
      class="d-flex flex-column flex-fill pt-5 pb-4 pl-2"
      @click-menu="$emit('close')"
      @logout="$emit('logout')"
    />
  </aside>
</template>

<script>
import MainMenu from "./MainMenu";

export default {
  components: { MainMenu },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
.templates-mobile-menu {
  position: fixed;
  z-index: 8;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  -ms-overflow-style: none;
  scrollbar-width: none;

  .progress-wrapper {
    color: white !important;
    padding: 12px 8px !important;

    .progress-bar {
      background: #367e99;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 575px) {
    width: 0px;
  }

  .templates-main-menu {
    flex: 1 1 auto;
    transition: width 0.3s;

    .btn-lock {
      position: absolute;
      right: 8px;
      top: 101px;
      border: none;
      border-radius: 24px;
      height: 24px;
      width: 24px;
      background: white;
      display: none;
      z-index: 3;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
      justify-content: center;
      align-items: center;
      padding: 0;
      color: rgba(51, 51, 51, 0.6);

      i {
        font-size: 16px !important;
      }

      @media screen and (max-width: 575px) {
        display: none !important;
      }
    }

    .btn {
      width: 100%;
      color: rgba(255, 255, 255, 0.8);
      display: flex;
      cursor: pointer;
      font-size: 14px;
      min-height: 40px;

      &.router-link-active:not(.home),
      &.router-link-exact-active {
        color: white;
        font-weight: bold;
        border-radius: 0;
        background: rgba(255, 99, 105, 0.05);
        border-right: 4px solid #fff;
      }

      span {
        white-space: nowrap;
        flex: 1 1 auto;
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &:hover {
        color: white;
      }
    }
  }

  transition: all 0.3s;

  &.locked {
    @media screen and (min-width: 576px) {
      width: 320px;
      padding-right: 20px;

      .btn-lock {
        display: flex;
      }
    }
  }

  &.open {
    width: 320px;
    padding-right: 20px;

    .btn-lock {
      display: flex;
    }
  }

  @media screen and (max-width: 991px) {
    .dropdown-menu {
      max-width: 260px;

      .dropdown-item {
        white-space: normal;
      }
    }
  }
}
</style>
