import Vue from "vue";
import Vuex from "vuex";

import actions from "./actions";
import state from "./state";
import mutations from "./mutations";

import user from "./user";
import modules from "./modules";
import lessons from "./lessons";
import forum from "./forum";
import courses from "./courses";
import releases from "./releases";

Vue.use(Vuex);

export default new Vuex.Store({
  actions,
  state,
  mutations,
  modules: {
    user,
    modules,
    lessons,
    forum,
    courses,
    releases
  }
});
