import get from "lodash/get";

export default {
  getAll(context) {
    return new Promise((resolve, reject) => {
      const productId = get(context.rootState, "setup.product_id") || 171;
      window.axios
        .get(`/courses/modules/list/${productId}?limit=1000`)
        .then(response => {
          context.commit("setModules", response.data.results);
          resolve();
        })
        .catch(reject);
    });
  },

  getDetails(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/courses/modules/${id}`)
        .then(response => {
          context.commit("setModule", response.data);
          resolve(response.data);
        })
        .catch(reject);
    });
  },

  setProgress(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/reports/progress", data)
        .then(resolve)
        .catch(reject);
    });
  },

  getLastLessons(context) {
    return new Promise((resolve, reject) => {
      const productId = get(context.rootState, "setup.product_id") || 171;
      window.axios
        .get(`/reports/last-lessons/${productId}`)
        .then(response => {
          context.commit("setLastLessons", response.data);
          resolve();
        })
        .catch(reject);
    });
  },

  getFAQ(context) {
    return new Promise((resolve, reject) => {
      const productId = get(context.rootState, "setup.product_id") || 171;
      window.axios
        .get(`/modules/faq/u/list/${productId}?limit=999`)
        .then(response => {
          context.commit("setFaq", response.data.results);
          resolve(response.data);
        })
        .catch(reject);
    });
  }
};
