<template>
  <div class="home-releases">
    <div
      class="responsive-bg d-lg-none"
      :style="
        `background-image: linear-gradient(to top, black 30%, transparent 40%), url(${release.cover})`
      "
    />
    <div class="release-card py-3 py-lg-0 px-3" :style="releaseStyle">
      <div class="row">
        <div class="col-lg-2 pl-lg-5">
          <p class="day">{{ day }}</p>
          <p class="month pl-2">{{ month }}</p>
        </div>
        <div class="col-lg-5 py-lg-4 px-lg-3 m-lg-0 pt-5 mt-5">
          <h4>{{ release.title }}</h4>
          <p class="py-3 mb-5">
            {{ release.description }}
          </p>
          <div
            class="d-flex align-items-center justify-content-between justify-content-lg-start pt-lg-5 pb-lg-4 py-1"
          >
            <button class="btn-info btn mr-lg-3 px-5" @click="goToLink">
              Saber mais
            </button>
            <button
              v-if="notification"
              class="btn btn-outline-secondary d-flex align-items-center px-4"
            >
              <i class="pr-2 icon-check"></i>
              Aviso enviado
            </button>

            <button
              v-else
              class="btn btn-outline-secondary d-flex align-items-center px-4"
              @click="modal = true"
            >
              <i class="pr-2 icon-notification"></i>
              Receber aviso
            </button>
          </div>
        </div>
        <div
          :style="`backgroundImage: url(${release.cover})`"
          class="img col-lg-5"
        />
      </div>
    </div>
    <releases-modal v-model="modal" :release="release" />
  </div>
</template>

<script>
import ReleasesModal from "./ReleasesModal.vue";
import get from "lodash/get";
export default {
  components: { ReleasesModal },
  props: {
    release: Object
  },
  data() {
    let hasNotification =
      get(this.release, "notification.status") === "enabled";
    return {
      modal: false,
      notification: hasNotification
    };
  },
  computed: {
    releaseStyle() {
      const releaseCard = this.$setup.theme === "dark" ? "#25222C" : "#ffffff";
      return {
        background: releaseCard
      };
    },
    day() {
      let date = new Date(this.release.release_at);
      return date.getDate();
    },
    month() {
      let date = new Date(this.release.release_at);
      let month = date.getMonth();
      const monthName = {
        0: "JAN",
        1: "FEV",
        2: "MAR",
        3: "ABR",
        4: "MAI",
        5: "JUN",
        6: "JUL",
        7: "AGO",
        8: "SET",
        9: "OUT",
        10: "NOV",
        11: "DEC"
      };
      return monthName[month];
    }
  },
  methods: {
    goToLink() {
      if (this.release.url) document.location.href = this.release.url;
    }
  }
};
</script>

<style lang="scss" scoped>
.home-releases {
  position: relative;

  .responsive-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.9;
  }
  .day {
    height: 68px;
    font-size: 70px;
  }

  .month {
    font-weight: 600;
    font-size: 22px;
  }

  .btn-info {
    background: #ee6469;
    color: #ffffff;
    border: none;
  }

  .img {
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
    border-radius: 0 8px 8px 0;
  }

  .icon-notification,
  .icon-check {
    font-size: 24px;
  }

  .release-card {
    width: 100%;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
  }

  @media screen and (max-width: 576px) {
    .day {
      height: 48px;
      font-size: 64px;
      line-height: 70px;
    }

    .month {
      font-weight: 600;
      font-size: 18px;
    }
  }
}
</style>
