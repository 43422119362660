<template>
  <a :href="link" target="_blank" class="home-related-course">
    <div class="img" :style="styles" />
    <div class="text">
      <h6 class="mt-3 mb-2">{{ name }}</h6>
    </div>
  </a>
</template>

<script>
import get from "lodash/get";

export default {
  props: {
    course: Object
  },
  computed: {
    styles() {
      const picture = get(this.course, "product.picture");
      const url = picture || require("@/assets/images/logo-white.png");

      const size = picture ? "cover" : "50%";

      // return `background-image: url(${url}); background-size: ${size}`;
      return `
        background: linear-gradient(0deg, rgba(0, 26, 24, 0.20) 0%, rgba(0, 26, 24, 0.20) 100%), url(${url}), lightgray -52.166px -6.755px / 128.148% 121.403% no-repeat, url(${url}), lightgray 50% / cover no-repeat;
        background-blend-mode: overlay, normal, normal;
      `;
      // background-size: ${size}`;
    },
    name() {
      return get(this.course, "name") || get(this.course, "product.name");
    },
    description() {
      const limit = 50;
      const text =
        get(this.course, "description") ||
        get(this.course, "product.description", "");

      const ellipsis = text.length > limit ? "..." : "";
      return `${text.slice(0, limit)}${ellipsis}`;
    },
    link() {
      return (
        get(this.course, "link_sale_page") ||
        get(this.course, "product.checkout_url")
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.home-related-course {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  position: relative;
  border-radius: 8px;
  border: 3px solid transparent;
  transition: border 0.3s ease-in-out, filter 0.3s ease-in-out;
  filter: brightness(70%);

  &:hover {
    border: 3px solid #6b3ead;
    border-radius: 10px;
    filter: brightness(100%);
  }

  .img {
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-color: black;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    border-radius: 6px;
    min-width: 150px;
    min-height: 150px;
  }

  small {
    opacity: 0.6;
    font-weight: 600;
  }

  .text {
    position: absolute;
    bottom: 3%;
    left: 5%;
    h6,
    small {
      color: #fff;
    }
  }
}
</style>
