<template>
  <b-modal
    class="my-groups-modal"
    @change="change"
    :visible="visible"
    hide-footer
    hide-header
    centered
  >
    <div class="mb-1 d-flex align-items-center justify-content-center">
      <img
        src="../../assets/images/telegram.png"
        alt="icone telegram"
        style="max-height:45px"
      />
      <h4 class="mb-0 ml-2">Telegram</h4>
    </div>
    <small class="text-danger">Atenção!</small>
    <small class="text-secondary">
      Para acessar os links, é necessário fazer o login no telegram em seu
      navegador.</small
    >
    <h5 class="py-3">Grupos que participo ({{ groups.length }})</h5>
    <div class="mt-4 py-2" v-for="(group, i) in groups" :key="i">
      <div class="d-flex align-items-center mb-2">
        <img
          src="../../assets/images/telegram.png"
          alt="icone telegram"
          style="max-height:32px;"
          class="pr-3"
        />
        <strong>{{ group.chat_name }}</strong>
      </div>
      <div class="pl-5 link">
        <a target="_blank" :href="group.group_invite_link.invite_link">{{
          group.group_invite_link.invite_link
        }}</a>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: "visible",
    event: "change"
  },
  methods: {
    change(val) {
      this.$emit("change", val);
    }
  },
  computed: {
    ...mapState("courses", ["groups"])
  }
};
</script>

<style lang="scss" scoped>
.my-groups-modal {
}
.link {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
::v-deep .modal-content {
  padding: 32px;
}
</style>
