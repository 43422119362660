import get from "lodash/get";

export default {
  getReleases(context) {
    return new Promise((resolve, reject) => {
      const productId = get(context.rootState, "setup.product_id") || 171;
      window.axios
        .get(`/next-releases/list/${productId}`)
        .then(response => {
          context.commit("setReleases", response.data.results);
          resolve(response.data.results);
        })
        .catch(reject);
    });
  },
  postNotification(context, params) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(`/next-releases/notifications`, params)
        .then(resolve)
        .catch(reject);
    });
  }
};
