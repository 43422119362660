<template>
  <footer class="templates-app-footer text-light w-100" :style="{ background }">
    <div
      class="container-fluid d-flex flex-column flex-md-row align-items-center"
    >
      <div class="py-3 text-center col-md-2 text-md-left">
        <small class="text-secondary">Redes Sociais</small>
        <social-links class="mt-1" />
      </div>
      <div class="py-3 text-center col-md-8">
        <div :class="`${logo} mx-auto`" />
        <div class="d-lg-flex justify-content-center">
          <div class="small text-secondary pt-3 pr-lg-4">
            ©{{ year }} – Blitzpay | Todos os Direitos Reservados
          </div>
          <!-- <div class="small pt-3">
            <a class="pr-4" href="#">Termos de uso</a>
            <a href="#">Privacidade e cookies</a>
          </div> -->
        </div>
      </div>
      <div class="py-3 col-md-2"></div>
    </div>
  </footer>
</template>

<script>
import SocialLinks from "./SocialLinks";

export default {
  components: { SocialLinks },
  computed: {
    year() {
      return new Date().getFullYear();
    },
    logo() {
      return this.$setup.theme == "dark" ? "logo" : "logo-light";
    },
    background() {
      // return this.$setup.theme == "dark" ? "transparent" : "#393444";
      return "transparent";
    }
  }
};
</script>

<style lang="scss" scoped>
.templates-app-footer {
  padding: 8px 0;

  a {
    font-weight: bold;
    text-decoration: none;
  }

  .logo-light,
  .logo {
    background-image: url("../../assets/images/logo-white.png");
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    width: 150px;
    height: 40px;
  }

  .logo-light {
    background-image: url("../../assets/images/logo-light.svg");
  }
}
</style>
