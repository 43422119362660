<template>
  <component
    :is="isBlocked ? 'div' : 'router-link'"
    class="home-module-card text-decoration-none"
    :style="style"
    :to="{ name: 'module', params }"
  >
    <div class="content text-light">
      <div v-if="isBlocked" class="small flex-fill">
        <h6 class="mb-0">Bloqueado</h6>
        <div v-if="daysToRelease">
          {{ daysToRelease }} dia{{ daysToRelease === 1 ? "" : "s" }} para
          liberar
        </div>
        <div v-if="blockedMessage">
          {{ blockedMessage }}
        </div>
      </div>
      <h5 class="title mb-0">{{ module.name }}</h5>
    </div>
    <span v-if="isBlocked" class="lock bg-dark">
      <img src="@/assets/images/icon-lock.png" />
    </span>
  </component>
</template>

<script>
import { get } from "lodash";

export default {
  props: {
    module: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      params: {
        id: this.module.id
      }
    };
  },
  computed: {
    style() {
      const picture = get(this.module, "picture");
      const url = picture || this.$setup.setup.logo || "";

      const size = picture ? "cover" : "30%";
      const borders =
        this.$setup.theme === "dark"
          ? `border: 3px solid #000000aa;`
          : "border: 3px solid #F8F8F9";
      return `
        background-image: linear-gradient(0deg, #0b0b1295 0%, #0b0b1200 80%), url(${url}); 
        background-size: cover, ${size};
        ${borders}
      `;
    },
    isBlocked() {
      return get(this.module, "is_blocked.status");
    },
    daysToRelease() {
      return get(this.module, "is_blocked.days_left_for_release");
    },
    blockedMessage() {
      return get(this.module, "is_blocked.message");
    }
  }
};
</script>

<style lang="scss" scoped>
.home-module-card {
  background-color: #393444;
  text-align: left;
  padding: 0;
  height: 0;
  width: 100%;
  display: block;
  padding-bottom: 150%;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  position: relative;
  transition: border 0.4s ease-in-out, transform 0.4s ease-in-out;
  border-radius: 2px;
  transform: scale(1);

  .title {
    font-weight: 500 !important;
    font-size: 16px !important;
    @media screen and (max-width: 768px) {
      font-size: 12px !important;
    }
  }

  .content {
    opacity: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    padding: 16px;
    // background: linear-gradient(0deg, #0b0b12 0%, #0b0b1200 100%);
    background: #0b0b1250;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: background 0.3s ease-in-out;
  }

  &:hover {
    border: 3px solid #6b3ead !important;
    transform: scale(0.98);
    .content {
      // background: rgba(249, 225, 225, 0.1);
      background: transparent;
      background-blend-mode: soft-light;
    }
  }

  .lock {
    max-width: 64px;
    max-height: 64px;
    min-width: 64px;
    min-height: 64px;
    border-radius: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
      max-height: 32px;
    }
  }
}
</style>
