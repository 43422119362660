<template>
  <div class="action-dispatcher">
    <template v-if="loading">
      <div
        v-if="small"
        class="d-flex justify-content-center align-items-center"
      >
        <b-spinner variant="primary" small />
      </div>
      <div
        v-else
        class="d-flex justify-content-center p-5"
        :class="loadingClass"
      >
        <b-spinner variant="primary" style="width: 3rem; height: 3rem;" />
      </div>
    </template>

    <div class="error p-4 text-center" v-else-if="error">
      Erro!
      <button class="btn-link link" @click="dispatch">
        Tentar novamente
      </button>
    </div>
    <slot v-else v-bind:data="data" />
  </div>
</template>

<script>
export default {
  props: {
    action: {
      type: String,
      required: true
    },
    parameters: {
      type: [Object, Number, String],
      default: null
    },
    loadingClass: {
      type: String,
      default: ""
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: true,
      error: false,
      data: null
    };
  },
  mounted() {
    this.dispatch();
  },
  methods: {
    dispatch() {
      this.loading = true;
      this.$store
        .dispatch(this.action, this.parameters)
        .then(data => {
          this.error = false;
          this.data = data;
          this.$emit("success", data);
        })
        .catch(error => {
          this.$emit("error", error);
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.action-dispatcher {
  .error {
    .link {
      padding: 0;
      border: none;
      background: transparent;
    }
  }
}
</style>
