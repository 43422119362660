<template>
  <div class="view-home">
    <header v-if="headerModule" :style="headerStyle">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-5">
            <div v-if="certificateAvailable">
              <h1>Certificado disponível</h1>
              <a
                :href="certificateUrl"
                target="_blank"
                class="btn btn-primary d-inline-flex align-items-center justify-content-center"
              >
                Baixar certificado
              </a>
            </div>
            <template v-else>
              <h1>{{ headerModule.name }}</h1>
              <p class="my-4" v-html="headerModule.description" />

              <div class="d-flex align-items-center justify-content-start">
                <action-dispatcher
                  class="mx-2"
                  action="lessons/getAll"
                  :parameters="headerModule.id"
                  small
                >
                  <router-link
                    v-if="currentLesson"
                    class="btn btn-primary d-inline-flex align-items-center justify-content-center"
                    :to="{
                      name: 'lesson',
                      params: { ...headerParams, lesson: currentLesson.id }
                    }"
                  >
                    <i class="icon-play" />
                  </router-link>
                </action-dispatcher>
                <router-link
                  class="btn btn-secondary px-5 d-inline-flex align-items-center mx-2"
                  :to="{
                    name: 'module',
                    params: headerParams
                  }"
                >
                  Saiba mais
                </router-link>
              </div>
            </template>
          </div>
        </div>
      </div>
    </header>

    <div class="container-fluid pt-5">
      <action-dispatcher action="modules/getLastLessons">
        <div
          v-if="lastLessons.length"
          class="card continue-lessons-card p-4 mb-5"
        >
          <div class="card-body px-2 py-0">
            <h5 class="mb-4">
              Continue assistindo
            </h5>

            <div class="row my-n3">
              <div
                v-for="(lesson, i) in progressModules"
                :key="i"
                class="col-xl-4 col-md-6 my-3 d-flex"
              >
                <progress-module-card class="flex-fill" :lesson="lesson" />
              </div>
            </div>
          </div>
        </div>
      </action-dispatcher>

      <h5 class="my-3">Todos os Módulos</h5>

      <carousel
        :per-page="1"
        :per-page-custom="[
          [200, 2],
          [400, 3],
          [768, 4],
          [992, 6]
        ]"
        :pagination-enabled="false"
        scroll-per-page
        navigation-enabled
        navigation-next-label="<i class='icon-chevron-right'></i>"
        navigation-prev-label="<i class='icon-chevron-left'></i>"
        class="mb-5"
      >
        <slide v-for="(module, i) in modules" :key="i" class="px-1">
          <module-card class="flex-fill" :module="module" />
        </slide>
      </carousel>

      <div v-for="(value, key) in modulesPerCategory" :key="key">
        <h5 class="my-3">{{ key }}</h5>
        <carousel
          :per-page="1"
          :per-page-custom="[
            [576, 2],
            [768, 3],
            [992, 4]
          ]"
          :pagination-enabled="false"
          scroll-per-page
          navigation-enabled
          navigation-next-label="<i class='icon-chevron-right'></i>"
          navigation-prev-label="<i class='icon-chevron-left'></i>"
          class="mx-n3 mb-5"
        >
          <slide v-for="(module, i) in value" :key="i" class="px-3">
            <module-card class="flex-fill" :module="module" />
          </slide>
        </carousel>
      </div>

      <action-dispatcher action="courses/getRelated">
        <template v-if="relatedCourses.length">
          <h5 class="my-3">
            Cursos relacionados
          </h5>

          <carousel
            :per-page="1"
            :per-page-custom="[
              [576, 2],
              [768, 3],
              [992, 4]
            ]"
            :pagination-enabled="false"
            scroll-per-page
            navigation-enabled
            navigation-next-label="<i class='icon-chevron-right'></i>"
            navigation-prev-label="<i class='icon-chevron-left'></i>"
            class="mb-5"
          >
            <slide v-for="(course, i) in relatedCourses" :key="i" class="px-1">
              <related-course class="flex-fill" :course="course" />
            </slide>
          </carousel>
        </template>
      </action-dispatcher>
    </div>
    <action-dispatcher
      action="releases/getReleases"
      :parameters="setup.product_id"
    >
      <template v-if="releases.length">
        <div class="my-3 d-none d-lg-block container-fluid">
          <h5>
            Próximos lançamentos
          </h5>

          <div v-for="(release, i) in releases" :key="i" class="my-3">
            <releases :release="release" />
          </div>
        </div>
        <div class="d-lg-none pt-3">
          <h5 class="ml-4 mb-3">
            Próximos lançamentos
          </h5>
          <carousel
            :scrollPerPage="false"
            :perPage="1"
            :pagination-enabled="false"
          >
            <slide v-for="(release, i) in releases" :key="i">
              <releases :release="release" />
            </slide>
          </carousel>
        </div>
      </template>
    </action-dispatcher>
  </div>
</template>

<script>
import ModuleCard from "@/components/home/ModuleCard";
import RelatedCourse from "@/components/home/RelatedCourse";
import ProgressModuleCard from "@/components/home/ProgressModuleCard";
import Releases from "@/components/home/Releases";
import { Carousel, Slide } from "vue-carousel";
import { mapState } from "vuex";
import get from "lodash/get";

export default {
  components: {
    ModuleCard,
    RelatedCourse,
    ProgressModuleCard,
    Releases,
    Carousel,
    Slide
  },
  computed: {
    ...mapState(["setup"]),
    ...mapState("modules", ["modules", "lastLessons", "module"]),
    ...mapState("lessons", ["lessons"]),
    ...mapState("user", ["certificateStatus"]),
    ...mapState("courses", ["relatedCourses"]),
    ...mapState("releases", ["releases"]),
    certificateAvailable() {
      return (
        this.setup.hourly_load &&
        get(this.certificateStatus, "certificate_status") === "available"
      );
    },
    modulesPerCategory() {
      return this.modules.reduce((prev, cur) => {
        if (!!cur.category) {
          return prev[cur.category_name]
            ? {
                ...prev,
                [cur.category_name]: [...prev[cur.category_name], cur]
              }
            : { ...prev, [cur.category_name]: [cur] };
        }
        return prev;
      }, {});
    },
    certificateUrl() {
      const hash = get(this.certificateStatus, "hash_token");
      const productId = get(this.certificateStatus, "product_id");
      return `https://api.members.blitzpay.com.br/v1/accounts/students/${hash}/product/${productId}/generate/certificate`;
    },
    progressModules() {
      return this.lastLessons.concat().slice(0, 3);
    },
    headerModule() {
      return get(this.modules, "[0]");
      // return this.modules.find(c => c.progress < 100);
    },
    headerParams() {
      return {
        id: get(this.headerModule, "id")
      };
    },
    headerStyle() {
      const colorFrom = this.$setup.theme === "dark" ? "black" : "#393444";
      const colorTo =
        this.$setup.theme === "dark" ? "rgba(0,0,0,0)" : "#39344400";
      const url = get(this.headerModule, "header")
        ? this.headerModule.header
        : get(this.headerModule, "picture");

      return {
        "background-image": `linear-gradient(to top, ${colorFrom}, ${colorTo} 50%),
          linear-gradient(to right, ${colorFrom}, ${colorTo} 50%),
          url('${url}')`
      };
    },
    currentLesson() {
      return (
        this.lessons &&
        this.lessons.find(
          lesson => !lesson.progress || !lesson.progress.finished_at
        )
      );
    }
  },
  mounted() {
    if (this.$setup.product_id) {
      this.$store.dispatch("user/getCertificateStatus");
    }
  }
};
</script>

<style lang="scss" scoped>
.view-home {
  header {
    position: relative;
    color: white;
    background-size: cover;
    background-position-x: center;
    background-position-y: center;

    @media screen and (max-width: 991px) {
      padding: 48px 0;
    }

    @media screen and (min-width: 992px) {
      padding: 90px 0 70px;
    }

    img {
      max-width: 100%;
      width: 300px;

      @media screen and (max-width: 991px) {
        width: 150px;
      }
    }
  }

  ::v-deep .VueCarousel {
    .VueCarousel-pagination {
      padding-right: 18px;
      position: absolute;
      top: -12%;
      left: 90%;
      transform: translateX(-90%);
      text-align: right;
    }
  }

  ::v-deep .continue-lessons-card {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
    border: none;
  }
}
</style>
