<template>
  <b-modal
    @change="change"
    :visible="value"
    size="xl"
    scrollable
    hide-footer
    hide-header
    centered
  >
    <div class="faq-modal-component">
      <h5 class="text-center text-light mb-4">FAQ</h5>
      <button
        class="btn text-light icon-close"
        @click="$emit('input', false)"
      ></button>

      <div class="row">
        <div
          v-for="(q, i) in questions"
          :key="`faq-${i}`"
          class="mb-1"
          :class="{
            'col-lg-12': questions.length < 4,
            'col-lg-6': questions.length > 4
          }"
        >
          <b-card no-body>
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle="`accordion-${q.id}`">
                {{ q.question }}
                <i class="icon-chevron-down"></i>
              </b-button>
            </b-card-header>
            <b-collapse
              :id="`accordion-${q.id}`"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>{{ q.answer }}</b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>

      <div v-if="!faq.length" class="py-4 text-center">
        <p>Nenhuma pergunta frequente cadastrada até o momento</p>
      </div>

      <div class="d-flex justify-content-center pt-4">
        <button class="btn btn-light" @click="$emit('input', false)">
          Fechar
        </button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { mapState } from "vuex";

export default {
  props: {
    value: Boolean
  },
  computed: {
    ...mapState("modules", ["faq"]),
    questions() {
      return [...this.faq].sort((a, b) => a.sort - b.sort);
    }
  },
  mounted() {
    this.$store.dispatch("modules/getFAQ").then();
  },
  methods: {
    change(val) {
      this.$emit("input", val);
    }
  }
};
</script>
<style lang="scss" scoped>
.faq-modal-component {
  .icon-close {
    position: absolute;
    top: 2%;
    right: 0%;
  }

  .card {
    border-radius: 4px;
    border: 1px solid var(--l-neutral-45);
    background-color: transparent;
  }

  .card-header {
    .btn,
    .btn-secondary,
    .btn-secondary:hover,
    .btn-secondary:active,
    .btn-secondary:focus-visible,
    .btn-secondary:not(:disabled):not(.disabled):active:focus {
      background-color: transparent;
      border-color: transparent;
      text-align: left;
      position: relative;
      outline: none;
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 13px;

      .icon-chevron-down {
        color: #fff;
      }

      &.not-collapsed {
        .icon-chevron-down {
          transform: rotate(180deg) !important;
        }
      }
    }
  }

  .card-text {
    color: #fff;
  }
}
</style>
