import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import VueCarousel from "vue-carousel";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./assets/icomoon/style.css";
import "./assets/scss/app.scss";
import "./components";
import "./plugins";
import { init } from "./setup/api";

Vue.use(BootstrapVue);
Vue.use(VueCarousel);

init();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

if (document.location.host.split(".")[0].toLocaleLowerCase() === "none") {
  document.location.href = "https://do-zero-ao-digital.club.blitzpay.com.br/";
}
