export function toHumanDate(date) {
  return date
    .split("-")
    .reverse()
    .join("/");
}

export function dayDiff(dateString) {
  var today = new Date();
  var date = new Date(dateString);

  // To calculate the time difference of two dates
  var differenceInTime = date.getTime() - today.getTime();

  // To calculate the no. of days between two dates
  return Math.floor(differenceInTime / (1000 * 3600 * 24));
}

export const emojis = [
  "😁",
  "😂",
  "😃",
  "😄",
  "😅",
  "😆",
  "😉",
  "😊",
  "😋",
  "😌",
  "😍",
  "😏",
  "😒",
  "😓",
  "😔",
  "😖",
  "😘",
  "😚",
  "😜",
  "😝",
  "😞",
  "😠",
  "😢",
  "😤",
  "😥",
  "😨",
  "😩",
  "😪",
  "😫",
  "😭",
  "😰",
  "😱",
  "😲",
  "😳",
  "😵",
  "😷",
  "😸",
  "😹",
  "😺",
  "😻",
  "😼",
  "😽",
  "😾",
  "😿",
  "🙀",
  "🙅",
  "🙆",
  "🙇",
  "🙈",
  "🙉",
  "🙊",
  "🙋",
  "🙌",
  "🙍",
  "🙎",
  "🙏"
];

export const formFields = (fields, initial = {}) => {
  const form = {};
  const errors = {};
  for (const i in fields) {
    const field = fields[i];
    form[field] = initial && initial[field] ? initial[field] : "";
    errors[field] = [];
  }
  return { form, errors, loading: false };
};

export const youtubeParser = url => {
  var regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|shorts\/|live\/|watch\?v=)([^#\&\?]*).*/;
  var match = url.match(regExp);
  return match && match[1].length == 11 ? match[1] : false;
};

export const vimeoParser = url => {
  var firstPart = url.split("?")[0].split("/");
  return firstPart[firstPart.length - 1];
};

export const daysBetweenDates = (firstDate, secondDate) => {
  const oneDay = 24 * 60 * 60 * 1000;
  return Math.ceil((firstDate - secondDate) / oneDay);
};

export const timeFromNow = date => {
  const removeTimeZone = d =>
    d[d.length - 1] == "Z" ? `${d}`.slice(0, d.length - 1) : d;

  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const olderDate = new Date(removeTimeZone(date));
  const currentDate = new Date();

  const diff = ((olderDate - currentDate) / _MS_PER_DAY) * -1;

  const formatter = new Intl.RelativeTimeFormat("pt-BR", {
    hourCycle: "h24",
    numeric: "auto",
    timeStyle: "short",
    style: "short",
    timeZone: "America/Sao_Paulo"
  });

  switch (true) {
    case diff > 365:
      return formatter.format(-Math.floor(diff / 365), "year");
    case diff > 30:
      return formatter.format(-Math.floor(diff / 30), "month");
    case diff > 7:
      return formatter.format(-Math.floor(diff / 7), "week");
    case diff > 1:
      return formatter.format(-Math.round(diff), "days");
    case diff > 0.04:
      return formatter.format(-Math.round(diff * 24), "hour");
    default:
      return formatter.format(-Math.round(diff * 24 * 60), "minutes");
  }
};
