import get from "lodash/get";

export default {
  getRelated(context) {
    return new Promise((resolve, reject) => {
      const productId = get(context.rootState, "setup.product_id") || 171;
      window.axios
        .get(`/courses/related/${productId}?limit=100`)
        .then(response => {
          context.commit("setRelatedCourses", response.data.results);
          resolve(response.data.results);
        })
        .catch(reject);
    });
  },

  getGroups(context) {
    return new Promise((resolve, reject) => {
      const productId = get(context.rootState, "setup.product_id") || 171;
      window.axios
        .get(`/telegram/invite/${productId}`)
        .then(({ data: { results } }) => {
          context.commit("setGroups", results);
          resolve();
        })
        .catch(reject);
    });
  }
};
