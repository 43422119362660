export default {
  addComments(state, comments) {
    state.comments = [...state.comments, ...comments];
  },
  setComments(state, comments) {
    state.comments = comments;
  },
  setReviews(state, reviews) {
    state.reviews = reviews;
  },
  setCount(state, count) {
    state.count = count;
  }
};
